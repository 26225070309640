<template>
    <b-card-code>
        <div v-if="$can('create','Class Routine')" class="custom-search d-flex">
            <router-link :to="{name:'class-routine-add'}">
                <b-button variant="outline-primary">
                    Add new
                </b-button>
            </router-link>
        </div>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </div>
            </b-form-group>
        </div>
        <div class="custom-search d-flex justify-content-start">
            <vue-excel-xlsx
                :data="getData"
                :columns="columns10"
                :filename="'filename'"
                :sheetname="'subjects'"
            >
                <b>Excel</b>
            </vue-excel-xlsx>
            <vue-json-to-csv :json-data="getData"
                             :csv-title="'My_CSV'"
            >
                <button>
                    <b>CSV</b>
                </button>
            </vue-json-to-csv>
            <button @click="pdfGenerate()">
                <b>PDF</b>
            </button>
        </div>
        <!-- table -->
        <vue-good-table
            style="white-space: nowrap;text-align: center;vertical-align : middle;"
            :columns="columns"
            :rows="class_routines"
            :rtl="direction"
            :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
            :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        >
            <template
                slot="table-row"
                slot-scope="props"
            >
                <!-- Column: index -->
                <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'class_period'">
          {{ props.row.class_period?moment(props.row.class_period.start_time,'hh:mm').format('hh:mm a')+' - '+moment(props.row.class_period.end_time,'hh:mm').format('hh:mm a'):'' }}
        </span>
                <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="$can('status change','Class Routine') && props.row.is_approved===1" :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
          <b-badge v-else-if="props.row.is_approved===1" :variant="statusVariant(props.row.is_active)">
            {{ props.row.is_active?'Active':'Deactivated' }}
          </b-badge>
          <b-badge v-else :variant="statusVariant(0)">
            {{ props.row.is_approved===0?'pending':'Declined' }}
          </b-badge>
        </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button v-if="!props.row.is_approved && (findAccess(props.row.id) || user.role_id===1)"
                        variant="primary" @click="approve(props.row.id)"
                        data-toggle="tooltip" data-placement="top" title="Approve"
              >
                <feather-icon class="text-body " icon="CheckIcon" size="15" />
              </b-button>
              <b-button v-if="!props.row.is_approved && (findAccess(props.row.id) || user.role_id===1)"
                        data-toggle="tooltip" data-placement="top" title="Declined"
                        variant="danger" @click="decliend(props.row.id)">
                <feather-icon class="text-body " icon="CrosshairIcon" size="15" />
              </b-button>
              <b-button v-if="!props.row.is_approved && props.row.approvalable.length<1 && user.role_id===1"
                        data-toggle="tooltip" data-placement="top" title="Forword"
                        variant="secondary" @click="showModal(props.row)">
                <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
              </b-button>
              <b-button v-if="!props.row.is_approved && findAccess(props.row.id)"
                        data-toggle="tooltip" data-placement="top" title="Forword"
                        variant="secondary" @click="showModal(props.row)">
                <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
              </b-button>
              </template>
          </span>
        </span>
            </template>

            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['10','15','20','50','100','500']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
        <!-- modal login-->
        <b-modal ref="my-modal"
                 hide-footer size="lg"
                 :title="'Foreword Information'"
        >
            <b-row>
                <b-col md="12">
                    <b-table-simple hover small caption-top responsive>
                        <b-thead>
                            <b-th class="text-center"  :colspan="1">Employee ID</b-th>
                            <b-th class="text-center"  :colspan="1">Employee Name</b-th>
                            <b-th class="text-center"  :colspan="1">Comment</b-th>
                            <b-th class="text-center"  :colspan="1">Status</b-th>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(grade,k) in selected_row.approvalable" :key="k">
                                <b-th class="text-center">{{ employeeIdById(grade.employee_id) }}</b-th>
                                <b-th class="text-center">{{ employeeNameById(grade.employee_id) }}</b-th>
                                <b-th class="text-center">{{ grade.comment }}</b-th>
                                <b-th class="text-center">{{ status3[parseInt(grade.status)] }}</b-th>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <!-- form -->
            <validation-observer ref="simpleRules">
                <b-form ref="leave_request">
                    <b-row>
                        <b-col md="6">
                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="employee"
                                    rules="required"
                                >
                                    <select name="employee_id" v-model="employee_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                                        <option  :value="null">Select One</option>
                                        <option v-for="(employee, key) in employees" :value="employee.id" :key="key">{{employeeNameById(employee.id)}}- {{employee.employee_id}}</option>
                                    </select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="comment"
                                    rules="required"
                                >
                                    <b-form-textarea
                                        v-model="comment"
                                        name="comment"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="comment"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- submit button -->
                        <b-col>
                            <b-button
                                variant="primary"
                                type="submit"
                                @click.prevent="validationForm"
                            >
                                {{ $t('Submit') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

    </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,BFormCheckbox,
    BModal,BFormTextarea,
    BForm,BRow,BCol,BTableSimple,BThead,BTr,BTh,BTbody,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment";
export default {
    name:'Classes',
    components: {
        BCardCode,
        VueGoodTable,ValidationProvider, ValidationObserver,
        BBadge,BFormCheckbox,
        BPagination,BModal,BFormTextarea,
        BForm,BRow,BCol,BTableSimple,BThead,BTr,BTh,BTbody,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BButton, VueJsonToCsv,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            moment,
            required,
            pageLength: 10,
            dir: false,
            model_mode:'add',
            selected_row:{},
            columns: [
                {
                    label: 'SL',
                    field: 'id',
                },
                {
                    label: 'Class',
                    field: 'classes.name',
                },
                {
                    label: 'Version',
                    field: 'version.name',
                },
                {
                    label: 'Shift',
                    field: 'shift.name',
                },
                {
                    label: 'Student Group',
                    field: 'student_group.name',
                },
                {
                    label: 'Section',
                    field: 'section.name',
                },
                {
                    label: 'Academic year',
                    field: 'academic_year.year',
                },
                {
                    label: 'Subject',
                    field: 'subject.name',
                },
                {
                    label: 'Class day',
                    field: 'class_day.name',
                },
                {
                    label: 'Class Period',
                    field: 'class_period',
                },
                {
                    label: 'Status',
                    field: 'is_active',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            searchTerm: '',
            status: {
                1: 'active',
                0: 'deactivate',
            },
            status2: {
                1: 'Yes',
                0: 'No',
            },
            status3:{
                0:'Pending',
                1:'Approved',
                2:'Foreword',
                3:'Declined'
            },
            employee_id:null,
            comment:'',
            columns10: [
                {
                    label: 'Class',
                    field: 'Class',
                },
                {
                    label: 'Version',
                    field: 'Version',
                },
                {
                    label: 'Shift',
                    field: 'Shift',
                },
                {
                    label: 'Student Group',
                    field: 'StudentGroup',
                },
                {
                    label: 'Section',
                    field: 'Section',
                },
                {
                    label: 'Academic Year',
                    field: 'AcademicYear',
                },
                {
                    label: 'Subject',
                    field: 'Subject',
                },
                {
                    label: 'Class Day',
                    field: 'ClassDay',
                },
                {
                    label: 'Class Period',
                    field: 'ClassPeriod',
                },
                {
                    label: 'Status',
                    field: 'Status',
                },
                {
                    label: 'Is Approverd',
                    field: 'IsApproverd',
                },
            ],
        }
    },
    methods:{
        statusChange(id){
            apiCall.put(`/class/routine/status/change${id}`).then((response)=>{
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_CLASS_ROUTINE');
            }).catch((error)=>{
                this.$toaster.error(error.response.data.message);
                this.$store.dispatch('GET_ALL_CLASS_ROUTINE');
            });
        },
        approve(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You want to approve!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, approve it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    apiCall.put(`/class/routine/approve${id}`).then((response)=>{
                        this.$toaster.success(response.data.message);
                        this.$store.dispatch('GET_ALL_CLASS_ROUTINE');
                    }).catch((error)=>{
                        this.$toaster.error(error.response.data.message);
                    });
                }
            })
        },
        decliend(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You want to declined this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, declined it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    apiCall.put(`/class/routine/declined${id}`).then((response)=>{
                        this.$store.dispatch('GET_ALL_CLASS_ROUTINE');
                        this.$swal({
                            icon: 'success',
                            title: 'Status Change!',
                            text: response.data.message,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    }).catch((error)=>{
                        this.$toaster.error(error.response.data.message);
                    });
                }
            })
        },
        validationForm(){
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    let data =new FormData(this.$refs.leave_request);
                    apiCall.post(`/class/routine/forward${this.selected_row.id}`,data).then((response)=>{
                        this.hideModal();
                        this.$toaster.success(response.data.message);
                        this.selected_row={};
                        this.employee_id=null;
                        this.comment='';
                        this.$store.dispatch('GET_ALL_CLASS_ROUTINE');
                    }).catch((error)=>{
                        if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
                        else this.$toaster.error(error.response.data.message);
                    });
                }
            })
        },
        findAccess(id){
            let ff = this.class_routines.find(item=>item.id===parseInt(id));
            if(ff){
                if(ff.approvalable.length<1) return false;
                else return ff.approvalable[ff.approvalable.length -1].employee_id===this.user.userable.id;
            }
            else return false;
        },
        showModal(row) {
            this.selected_row=row;
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        pdfGenerate(){
            /*only change able*/
            let title="Class Routine";
            let clm=['Class','Version','Shift','StudentGroup','Section', 'AcademicYear','Subject', 'ClassDay', 'ClassPeriod', 'Status', 'IsApproverd'];
            /*change able end*/
            let data =new FormData();
            data.append('title',title);
            data.append('columns',JSON.stringify(clm));
            data.append('data',JSON.stringify(this.getData));
            apiCall.post('/get/common/table/export/pdf',data,{responseType: 'blob'}).then((response)=>{
                const content = response.headers['content-type'];
                download(response.data,'subject.pdf', content)
            }).catch((error) => {
                this.$toaster.error(error.response.data.message);
            });
        },
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                1 : 'light-success',
                0 : 'light-danger',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
        ...mapGetters(['class_routines','employees','employeeIdById','employeeNameById']),
        user(){
            return JSON.parse(localStorage.getItem('userData'))
        },
        getData(){
            return this.class_routines.map(item => ({
                Class: item.classes?item.classes.name:'',
                Version: item.version?item.version.name:'',
                Shift: item.shift?item.shift.name:'',
                StudentGroup: item.student_group?item.student_group.name:'',
                Section: item.section?item.section.name:'',
                AcademicYear: item.academic_year?item.academic_year.year:'',
                Subject: item.subject?item.subject.name:'',
                ClassDay: item.class_day?item.class_day.name:'',
                ClassPeriod: item.class_period?item.class_period.start_time + "-" + item.class_period.end_time :'',
                Status: item.is_active ? 'Active' : 'Inactive',
                IsApproverd: item.is_approved ? 'Approved' : 'Not Approved'
            }));
        }
    },
    created() {
        this.$store.dispatch('GET_ALL_CLASS_ROUTINE');
        if(this.employees.length <1) this.$store.dispatch('GET_ALL_EMPLOYEE');
    },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>